export function loadFile(event, image) {
    const files = event.target?.files;
    if (files && files.length) {
        const fr = new FileReader();
        fr.onload = function () {
            if (image) {
                image.hidden = false;
                image.src = fr.result;
            }
        };
        fr.readAsDataURL(files[0]);
    }
}
