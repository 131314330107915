import { addClasses, removeClasses } from 'lambda-dom';
import EventDelegation from '@jjwesterkamp/event-delegation';
import { Subject } from 'rxjs';
import { delay } from '@app-lib/common.lib';
export class Modal {
    constructor(container) {
        this.container = container;
        this.openedSubject = new Subject();
        this.closedSubject = new Subject();
        this.closeRequests = EventDelegation
            .within(this.container)
            .events('click')
            .select('[data-close-modal]')
            .listen((event) => {
            // This auto submits the follow-up form
            event.preventDefault();
            this.close();
        });
        this.opened$ = this.openedSubject.asObservable();
        this.closed$ = this.closedSubject.asObservable();
    }
    async open() {
        addClasses('active')(this.container);
        await delay(200); // Same as the fade transition time
        this.openedSubject.next();
    }
    async close() {
        removeClasses('active')(this.container);
        await delay(200); // Same as the fade transition time
        this.closedSubject.next();
    }
    destroy() {
        this.openedSubject.complete();
        this.closedSubject.complete();
        this.closeRequests.remove();
    }
}
