import { queryAll, touchElement } from "lambda-dom";
import EventDelegation from "@jjwesterkamp/event-delegation";
let formSubmit = false;
let oldValues = new Map([]);
let changeList = new Map([]);
let numOfOptionsChanged = 0;
export function initCheckForChanges() {
    let changeCheckElements = queryAll('.change-check');
    changeCheckElements.forEach((element) => {
        if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement || element instanceof HTMLTextAreaElement) {
            if (element instanceof HTMLInputElement) {
                if (element.type === 'checkbox') {
                    setStartValue(element.name, element.checked.toString());
                    return;
                }
            }
            setStartValue(element.name, element.value);
        }
    });
}
export function attachChangeListeners() {
    EventDelegation
        .global()
        .events('change')
        .select('.change-check')
        .listen((event) => {
        changeValue(event.delegator);
    });
    EventDelegation
        .global()
        .events('click')
        .select('button.change-check')
        .listen((event) => {
        changeValue(event.delegator);
    });
    touchElement('form#edit-product-form', (form) => {
        form.addEventListener('submit', () => {
            formSubmit = true;
        });
    });
}
function setStartValue(elementName, value) {
    oldValues.set(elementName, value);
}
export function changeValue(element) {
    // If a DIV has been provided, search the DIV for the input element
    if (element instanceof HTMLDivElement) {
        for (let i = 0; i < element.childElementCount; i++) {
            if (element.children[i].classList.contains('change-check')) {
                element = element.children[i];
                break;
            }
        }
    }
    if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement || element instanceof HTMLTextAreaElement) {
        // Check if the element is a checkbox input
        if (element instanceof HTMLInputElement) {
            if (element.type === 'checkbox') {
                updateChangeList(element.name, element.checked.toString());
                return;
            }
        }
        updateChangeList(element.name, element.value);
    }
    // Buttons are usually used for adding/removing options
    else if (element instanceof HTMLButtonElement) {
        if (element.classList.contains('add-option')) {
            numOfOptionsChanged++;
        }
        else if (element.classList.contains('remove-option')) {
            numOfOptionsChanged--;
        }
    }
}
function updateChangeList(elementName, value) {
    if (value !== oldValues.get(elementName)) {
        changeList.set(elementName, value);
    }
    else {
        changeList.delete(elementName);
    }
}
function hasChanges() {
    return changeList.size > 0 || numOfOptionsChanged !== 0;
}
export function attachUnloadEvent() {
    window.addEventListener('beforeunload', function (e) {
        if (!formSubmit && hasChanges()) {
            e.returnValue = 'You have unsaved changes';
            return 'You have unsaved changes';
        }
    });
}
