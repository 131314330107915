import { BehaviorSubject } from 'rxjs';
export class LocalStorageItem {
    static create(key, defaultValue, serializer, parser) {
        return new LocalStorageItem(key, defaultValue, serializer ?? JSON.stringify, parser ?? JSON.parse);
    }
    constructor(key, defaultValue, serializer, parser) {
        this.key = key;
        this.defaultValue = defaultValue;
        this.serializer = serializer;
        this.parser = parser;
        this.dataSubject = new BehaviorSubject(this.getStoredData());
        this.data$ = this.dataSubject.asObservable();
    }
    patchCurrentData(fn) {
        const nextData = fn(this.getCurrentData());
        this.setStoredData(nextData);
        this.dataSubject.next(nextData);
    }
    currentDataSatisfies(fn) {
        return fn(this.getCurrentData());
    }
    getCurrentData() {
        return this.dataSubject.getValue();
    }
    getStoredData() {
        const storedData = localStorage.getItem(this.key);
        return storedData
            ? this.parser(storedData)
            : this.defaultValue;
    }
    setStoredData(x) {
        localStorage.setItem(this.key, this.serializer(x));
    }
}
