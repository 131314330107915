import { exhaustMap, interval, NEVER, switchMap } from 'rxjs';
import { always, any, append, isEmpty, partition, pluck, prop, propEq } from 'ramda';
import axios from 'axios';
import { LocalStorageItem } from '@app-components/local-storage-item.component';
import { hide, innerHTML, show, touchAll } from 'lambda-dom';
import EventDelegation from '@jjwesterkamp/event-delegation';
// ------------------------------------------------------------------------------
//      Types
// ------------------------------------------------------------------------------
/**
 * The enumeration of all file export statuses.
 */
export var FileExportStatus;
(function (FileExportStatus) {
    FileExportStatus["PENDING"] = "Pending";
    FileExportStatus["PROCESSING"] = "Processing";
    FileExportStatus["FINISHED"] = "Finished";
    FileExportStatus["FAILED"] = "Failed";
})(FileExportStatus || (FileExportStatus = {}));
/**
 * The enumeration of all file export types.
 */
export var FileExportType;
(function (FileExportType) {
    FileExportType["ORDERS"] = "Orders";
})(FileExportType || (FileExportType = {}));
// ------------------------------------------------------------------------------
//      Setup
// ------------------------------------------------------------------------------
const processingExports = LocalStorageItem.create('file-exports:processing', []);
const successfulExports = LocalStorageItem.create('file-exports:successful', []);
processingExports.data$.pipe(switchMap((exports) => {
    return isEmpty(exports)
        ? NEVER
        : interval(5000).pipe(exhaustMap(() => pollStatuses(exports)));
})).subscribe((result) => {
    const [successful] = partitionResponseExports(result);
    if (isEmpty(successful)) {
        return;
    }
    addToSuccessfulExports(successful);
    removeFromProcessingExports(successful);
});
touchAll([
    'div#exports-notification-container',
    'ul#exports-notification-list',
], (container, list) => {
    EventDelegation
        .within(container)
        .events('click')
        .select('[data-dismiss-export]')
        .listen(({ delegator }) => {
        successfulExports.patchCurrentData((xs) => {
            return xs.filter((x) => x.id !== parseInt(delegator.dataset.dismissExport));
        });
    });
    EventDelegation
        .within(container)
        .events('click')
        .select('[data-download-export]')
        .listen(({ delegator }) => {
        successfulExports.patchCurrentData((xs) => xs.map(({ id, description, url, downloaded }) => ({
            id,
            description,
            url,
            downloaded: id === parseInt(delegator.dataset.downloadExport) || downloaded,
        })));
    });
    successfulExports.data$.subscribe((result) => {
        if (isEmpty(result)) {
            hide(container);
            innerHTML(null)(list);
        }
        else {
            show(container);
            innerHTML(result.map(createExportLine).join(''))(list);
        }
    });
});
// ------------------------------------------------------------------------------
//      API
// ------------------------------------------------------------------------------
export function addFileExport(id, description) {
    if (hasFileExport(id))
        return false;
    processingExports.patchCurrentData(append({ id, description }));
    return true;
}
// ------------------------------------------------------------------------------
//      Private functions
// ------------------------------------------------------------------------------
function hasFileExport(id) {
    return processingExports.currentDataSatisfies(any(propEq('id', id)))
        || successfulExports.currentDataSatisfies(any(propEq('id', id)));
}
function pollStatuses(exports) {
    return axios
        .get('/admin/file-exports/statuses', { params: { ids: pluck('id', exports) } })
        .then(prop('data'))
        .catch(always([]));
}
function partitionResponseExports(xs) {
    return partition(propEq('status', FileExportStatus.FINISHED), xs);
}
function addToSuccessfulExports(successful) {
    successfulExports.patchCurrentData((xs) => xs.concat(successful.map(({ id, url }) => ({
        id,
        url,
        downloaded: false,
        description: processingExports.getCurrentData().find(propEq('id', id))?.description ?? url,
    }))));
}
function removeFromProcessingExports(successful) {
    const successfulIds = pluck('id', successful);
    processingExports.patchCurrentData((xs) => xs.filter((x) => !successfulIds.includes(x.id)));
}
function createExportLine({ id, description, url, downloaded }) {
    return `
    <li>
        <span class="title">Ready for download</span>
        <span class="descr">${description}</span>
        ${downloaded ? `<button class="dismiss-button  btn btn-primary btn-sm" type="button" data-dismiss-export="${id}">Dismiss</button>` : ''}
        <a class="download-button btn ${downloaded ? 'btn-light' : 'btn-primary'} btn-sm"
            href="${url}" data-download-export="${id}"
           target="_blank"
        >
            Download file ${downloaded ? 'again' : ''}
        </a>
    </li>
    `;
}
