import { touchElement } from 'lambda-dom';
function setAllFormFieldChangeEvents(ids) {
    ids.forEach(setFormFieldChangeEvent);
}
function setFormFieldChangeEvent(id) {
    touchElement(`#${id}`, (input) => {
        input.addEventListener('change', formFieldChangeEvent);
    });
}
function formFieldChangeEvent() {
    const url = new URL(window.location.href.toString());
    url.searchParams.set(this.name, this.value);
    window.location.href = url.href;
}
export { setAllFormFieldChangeEvents, setFormFieldChangeEvent, formFieldChangeEvent };
